<template>
  <div class="mx-3 sm:mx-3 md:w-8 md:mx-auto md:mt-4 lg:w-8 lg:mx-auto lg:mt-4">
    <Toast />
    <div v-if="notFound" class="flex align-items-center justify-content-center flex-wrap" style="height: 100vh">
        <h5>Questionário não encontrado!</h5>
    </div>
    <div v-if="respondido" class="flex align-items-center justify-content-center flex-wrap" style="height: 100vh">
        <h5>Questionário respondido. Obrigado!</h5>
    </div>
    <AppLoadingWrapper v-if="loading" />
    <Card v-if="!loading && !respondido">
        <template #title>
        <text style="font-size: 28px">Questionário</text>
        </template>
        <template #subtitle>
        Preencha o questionário abaixo
        </template>
        <template #content>
            <div class="grid">
                <div class="col-12" v-for="(item, index) in questionarios" :key="index">
                    <text style="font-size: 14px">{{ index+1 }}- {{ item.pergunta.descricao }}</text>
                    <br />
                    <InputNumber v-if="item.pergunta.tipo === 'IN'" id="" v-model="item.pergunta.resposta" style="width: 100%; margin-top: 4px; margin-bottom: 12px;"/>

                    <InputText v-if="item.pergunta.tipo === 'DI'" id="" v-model="item.pergunta.resposta" style="width: 100%; margin-top: 4px; margin-bottom: 12px;"/>

                    <div class="grid field-radiobutton" v-if="item.pergunta.tipo === 'ME'" style="margin-top: 4px; align-items: baseline;">
                        <div v-for="(opcoes, index) in item.pergunta.opcoes" :key="index" class="col-6">
                            <RadioButton :value="opcoes" v-model="item.pergunta.resposta" class="mr-1 pt-1"/>
                            <label>{{opcoes}}</label>
                        </div>
                    </div>

                    <div class="grid field-radiobutton" v-if="item.pergunta.tipo === 'CB'" style="margin-top: 4px; align-items: baseline;">
                        <div v-for="(opcoes, index) in item.pergunta.opcoes" :key="index" class="col-6">
                            <Checkbox :value="opcoes" v-model="item.pergunta.resposta" class="mr-1 pt-1"/>
                            <label class="mt-5">{{opcoes}}</label>
                        </div>
                    </div>
                    <Divider />
                </div>
            </div>
        </template>
        <template #footer>
            <Button icon="pi pi-check" label="Salvar" @click="save" />
        </template>
    </Card>
  </div>
</template>

<script>
import { getClientBase } from '@/services/http'

export default {
  data() {
    return {
        questionarios: [],
        loading: true,
        idFuncionario: null,
        respondido: false,
        notFound: false,
        questionarioFuncionario: null,
    }
  },
  async mounted () {
    this.loading = true;
    const responseQuestionarios = await getClientBase().get(`/questionario-funcionario/token/${this.$route.params.token}`);

    if (!responseQuestionarios.data) {
        this.notFound = true;
    }
    this.idFuncionario = responseQuestionarios.data.idFuncionario;
    this.questionarios = responseQuestionarios.data.questionario.questionarioPergunta;
    this.questionarioFuncionario = responseQuestionarios.data;
    if (responseQuestionarios.data.questionario.questionarioResposta.length <= 0) {
        await this.saveAccess();
    } else if (responseQuestionarios.data.status) {
        this.respondido = true;
    }

    this.loading = false;
  },
  methods: {
    async saveAccess() {
        const respostas = [];
        for(const questionario of this.questionarios) {
            respostas.push({
                idPergunta: questionario.pergunta.id,
                resposta: '',
                idQuestionario: questionario.idQuestionario,
                idFuncionario: this.idFuncionario
            });
        }

        await getClientBase().post('/questionario-resposta', respostas);
    },
    async save() {
        const respostas = [];
        for(const questionario of this.questionarios) {
            if (questionario.pergunta?.resposta) {
                respostas.push({
                    idPergunta: questionario.pergunta.id,
                    resposta: questionario.pergunta.resposta,
                    idQuestionario: questionario.idQuestionario,
                    idFuncionario: this.idFuncionario
                });
            } else {
                this.$toast.add({ severity: 'warn', summary: 'Responda todas as perguntas!', life: 3000 });
                return;
            }
        }

        const data = {
            id: this.questionarioFuncionario.id,
            companyId: this.questionarioFuncionario.companyId,
            idFuncionario: this.questionarioFuncionario.idFuncionario,
            idQuestionario: this.questionarioFuncionario.idQuestionario,
            status: true,
            token: this.questionarioFuncionario.token,
        }
        await getClientBase().patch('/questionario-resposta', respostas);
        await getClientBase().patch('/questionario-funcionario', data)
        this.$toast.add({ severity: 'success', summary: 'Questionário salvo!', life: 3000 });
        this.respondido = true;
    }
  }
};
</script>
